<template>
    <div :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''" class="main-card mb-3 card">
        <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header-tab card-header">
            <div :class="[this.$vuetify.theme.dark? 'white--text' : '']" class="card-header-title card-title font-size-lg text-capitalize font-weight-normal">
                {{$t('generic.lang_EventLog')}}
            </div>
        </div>
        <div class="card-body">
            <div class="scroll-area-lg">
                    <div class="vertical-time-icons vertical-timeline vertical-timeline--animate vertical-timeline--one-column" v-if="!loading">
                        <div class="vertical-timeline-element" v-for="(item,i) in data" :key="i">
                            <div>
                                <span class="vertical-timeline-element-icon bounce-in">

                                    <div v-if="isSupported(item)" :class="'timeline-icon '+events[item.eventType].style">
                                        <font-awesome-icon :icon="events[item.eventType].icon" :class="events[item.eventType].iconColor"/>
                                    </div>
                                    <div v-else class="timeline-icon border-primary">
                                        <font-awesome-icon :icon="['fal','newspaper']"/>
                                    </div>
                                </span>
                                <div class="vertical-timeline-element-content bounce-in">
                                    <h4 class="timeline-title">{{ item.first_name+' '+item.last_name}}</h4>
                                    <p>
                                        <span v-if="item.eventLog!='[]'">
                                            {{$t('dashboard.lang_userSold')}} <span class="text-primary">{{JSON.parse(item.eventLog).qtyItems+itemNumber(item)}}</span>
                                            {{$t('dashboard.lang_totalAmountOf')}} <span class="text-success">{{JSON.parse(item.eventLog).total|currency}}</span>
                                        </span>
                                        <span v-else>{{item.eventType+" "}}</span>
                                        <span class="text-info">{{" "+getTime(item.timestamp)}}</span></p>
                                    <p> {{$t('dashboard.lang_onCashier')}} <span class="text-primary">{{"n° "+item.cashierID}}</span></p>
                                    <router-link v-if="item.eventLog!='[]'" :to="{path:'/accounting/DisplayInvoice/'+JSON.parse(item.eventLog).invoiceUUID}">{{$t('dashboard.lang_displayInvoice')}}</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                <div class="row justify-center" v-if="!(loading) && emptyData">
                    <div class="col-12 text-center" ><h3>{{$t('dashboard.lang_noNewEvents')}}</h3></div>
                </div>
                <div class="row justify-center">
                    <div class="col-12" v-if="loading">
                        <v-skeleton-loader
                                v-for="n in 5"
                                :key="n"
                                height="94"
                                type="list-item-avatar-three-line"
                        >
                        </v-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import { library } from '@fortawesome/fontawesome-svg-core'
    import {fal} from '@fortawesome/pro-light-svg-icons'
    import {fas} from '@fortawesome/pro-solid-svg-icons'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import {mapState} from "vuex";
    import moment from 'moment';

    // GET/CREATE/UPDATE/DELETE/LOGIN/LOGOUT/CASHDRAWER/VOID/CASHOUT
    //{"type":"RETAIL","tableName":"","partyName":"","total":5.72,"qtyItems":6,"invoiceUUID":"2bd6b6d7-bc61-11ea-aa95-0a1c9e06025b"}
    // /accounting/DisplayInvoice/invoiceUUID
    library.add(
        fal,fas
    )

    export default {
        name: "ActivitiesComponent",
        components:{
            VuePerfectScrollbar,FontAwesomeIcon
        },
        data(){
            return{
                timer:null,
                loading:true,
                events: {
                    /*
                    *'eventType':
                    * {
                    *   style:"border-color", => the circle's color, use one of the known colors 'primary,warning etc...'
                    *   icon:['fal/fas/fad/far','icons-name'],
                    *   fontColor
                    *
                    * */
                    'LOGIN': {style:"border-success", icon:['fal','sign-in-alt'],iconColor:"text-info",},
                    'LOGOUT':{style:"border-primary",icon:['fal','sign-out-alt'],iconColor:"text-danger",},
                    'DELETE':{style:"border-primary",icon:['fal','trash-alt'],iconColor:"text-danger",},
                    'CASHDRAWER':{style:"border-info",icon:['fas','cash-register'],iconColor:"text-success",},
                    'CASHOUT':{style:"border-success",icon:['fal','cash-register'],iconColor:"text-success",}
                },
                data:[],
            }
        },
        computed:{
            ...mapState([
                'api'
            ]),
            emptyData(){
                return !(Object.values(this.data).length>0);
            }
        },
        methods:{
            syncronise(){
                this.loading=true;
                this.axios.post('get/dashboard/logEntries/').then(res=>{
                    if(res.data.success){
                        this.data=Object.values(res.data.data);
                    }
                    this.loading=false;
                }).catch(err=>{
                    this.loading=false;
                });
            },
            getTime(time){
                return  moment.unix(time)
                        .calendar(null,{sameDay : '['+this.$t('generic.lang_todayAt')+'] HH:mm',lastDay : '['+this.$t('generic.lang_yesterdayAt')+'] HH:mm',lastWeek : '[last] dddd [at] HH:mm',})
            },
            isSupported(item){
                return this.events[item.eventType]!=={};
            },
            itemNumber(item){
                return (JSON.parse(item.eventLog).qtyItems===1)?" "+this.$t('erp.lang_item'):" "+this.$t('erp.lang_items');
            }
        },

        mounted() {
            let self = this;
            this.timer = setInterval(self.syncronise(),1000*60*5);
        },
        beforeDestroy(){
            clearInterval(this.timer);
        }
    }
</script>

<style scoped>

</style>