<template>
    <v-container fluid v-if="$store.getters['permissions/checkPermission']('show_dashboardContent')">
        <v-row>
            <v-col cols="12" sm="12" md="6" lg="7">
                <all-cashier-daily-report-component/>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="5">
                <notification-component/>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12">
                <kassen-component/>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12">
                <sales-chart/>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col class="col">
                <best-selling-products-component/>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12">
                <activities-component/>
            </v-col>
        </v-row>
        <v-row v-if="parseInt(this.settings.settings.migrationEnabled) == 1" no-gutters>
            <v-col cols="12">
                <MigrateSales/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import SalesChart from "../../components/dashboard/SalesChart";
    import BestSellingProductsComponent from "../../components/dashboard/BestSellingProductsComponent";
    import AllCashierDailyReportComponent from "../../components/dashboard/AllCashierDailyReportComponent";
    import NotificationComponent from "../../components/dashboard/NotificationComponent";
    import KassenComponent from "../../components/dashboard/KassenComponent";
    import ActivitiesComponent from "../../components/dashboard/ActivitiesComponent";
    import MigrateSales from "../../components/dashboard/MigrationTest";
    import {mapState} from "vuex";

    export default {
      components: {
        ActivitiesComponent,
        KassenComponent,
        NotificationComponent,
        AllCashierDailyReportComponent,
        BestSellingProductsComponent,
        SalesChart,
        MigrateSales
      },

      data: () => ({
        heading: 'Herzlich Willkommen',
        subheading: 'Hier sehen Sie Ihre Kasse im Überblick',
        icon: 'pe-7s-home icon-gradient bg-tempting-azure',
      }),
      computed: {
        ...mapState([
          'pos',
          'settings'
        ]),
      }
    }
</script>